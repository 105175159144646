import { Product, scaleImage } from '@news-mono/web-common'
import React, {
    Fragment,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react'
import cx from 'classnames'
import {
    pnVideoClassname,
    StyledWrapper,
} from '../../../../content/Video/Video.styled'
import { getPosterImage } from '../../../../content/Video/utils/poster'
import { getImageWidth } from '../../../../content/Video/utils/product-info'
import { PNVideoTopBar } from '../components/PNVideoTopBar/PNVideoTopBar'
import { StyledPNVideo } from './PNVideo.styled'
import { PNPlayOverlay } from '../components/PNPlayOverlay/PNPlayOverlay'
import { PNVideoPlayButton } from '../../buttons/PNVideoPlayButton'
import { PNRenderPlayerParams } from './PNPlayerInterface'
import { isMobile } from '../../../../content/Video/utils/can-autoplay'

export const videoWrapperClassName = 'video-wrapper'
export const pnDismissControlClassName = 'pn-control-dismiss'
export const pnHidePlayButtonClassName = 'pn-play-hidden'

export const PNVideoSkin: React.FC<PNRenderPlayerParams> = ({
    wrapperElProps,
    videoElement,
    playerState,
    playerOptions,
    playerActions,
    canAutoPlay,
}) => {
    const {
        adStarted,
        adPlayed,
        adPaused,
        showTopBar,
        videoStarted,
        videoPlaying,
        videoPaused,
    } = playerState
    const { playVideo, pauseVideo, handleUserInactive } = playerActions
    const [isInitialPlay, setIsInitialPlay] = useState(true)
    const [controlHidden, setControlHidden] = useState(true)

    const isTouchDevice = typeof navigator !== 'undefined' && isMobile.any()
    const { innerRef, ...StyledVideoWrapperProps } = wrapperElProps
    const posterImageCrop = getPosterImage(playerState.currentVideo)
    let posterImageScaled: string | undefined
    const controlHideTimeout: MutableRefObject<NodeJS.Timeout | null> =
        useRef(null)

    const controlVisible = (adStarted || adPlayed) && showTopBar

    useEffect(() => {
        const duration = 3000
        controlVisible && setControlHidden(false)

        if (videoPaused || adPaused) {
            clearTimeout(controlHideTimeout.current as NodeJS.Timeout)
            if (!showTopBar) {
                // Set false on mouse leave
                setControlHidden(true)
                handleUserInactive()
            } else {
                setControlHidden(false)
            }
        } else if (!showTopBar) {
            // Set false on mouse leave
            setControlHidden(true)
            handleUserInactive()
        } else {
            // If video is playing
            clearTimeout(controlHideTimeout.current as NodeJS.Timeout)

            controlHideTimeout.current = setTimeout(() => {
                setIsInitialPlay(false)
                // If video hasn't been paused since timeout started hide controls
                if (!videoPaused || !adPaused) {
                    setControlHidden(true)
                    // Sets showTopBar to false
                    handleUserInactive()
                }
            }, duration)

            return () => {
                clearTimeout(controlHideTimeout.current as NodeJS.Timeout)
            }
        }
    }, [
        controlVisible,
        handleUserInactive,
        isInitialPlay,
        videoPaused,
        showTopBar,
        videoStarted,
        adPaused,
    ])

    const renderTopBar = (
        <PNVideoTopBar
            compactMode={playerOptions.compactMode}
            visible={!controlHidden}
            videoDetails={playerState.currentVideo}
            isAdPlaying={adStarted && !adPlayed}
        />
    )

    const renderPlayButton = (
        <PNVideoPlayButton
            overlayButtonScale="L"
            isCentred
            status={
                videoStarted && videoPlaying
                    ? 'playing'
                    : videoStarted && !videoPlaying
                    ? 'paused'
                    : 'hidden'
            }
            isTouchDevice={isTouchDevice}
        />
    )

    const renderPlayOverlay =
        adStarted || videoStarted ? null : (
            <PNPlayOverlay
                play={playerActions.playVideo}
                isCentered={true}
                isSmall={playerOptions.smallPlayButton}
                autoPlay={canAutoPlay}
            />
        )

    const extraClassNames = cx(
        controlHidden ? pnDismissControlClassName : '',
        videoStarted && !isTouchDevice ? pnHidePlayButtonClassName : '',
    )

    if (posterImageCrop) {
        posterImageScaled = scaleImage(
            posterImageCrop.reference,
            getImageWidth(Product.PerthNow),
            false,
        )
    }

    return (
        <Fragment>
            {posterImageScaled && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                            @media print {
                                .${pnVideoClassname}::before {
                                    content: url("${posterImageScaled}");
                                    max-width: 600px;
                                }
                            }`,
                    }}
                />
            )}
            <StyledWrapper
                className={videoWrapperClassName}
                compactMode={playerOptions.compactMode}
                hide={playerOptions.compactMode}
            >
                {renderPlayOverlay}
                <StyledPNVideo
                    {...StyledVideoWrapperProps}
                    ref={innerRef}
                    posterImage={posterImageScaled}
                    className={extraClassNames}
                >
                    {renderTopBar}
                    {renderPlayButton}
                    {videoElement}
                </StyledPNVideo>
            </StyledWrapper>
        </Fragment>
    )
}
